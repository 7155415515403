.question{
  text-align: left;
}

.participant-container{
  /* width: 50%;
  margin: 5% auto; */
}

.participant-header{
  color: blueviolet;
  text-align: center;
  margin-top: 50px;
}

#maincontainer{
  /* min-height: 100vh; */

  /* background: linear-gradient(130deg, #141414, 76%, #14ABCF 76%); */
  /* background-color: blue; */
  min-width: 100%;
  display: flex;
  box-shadow: 0 0 4px 2px rgba(235,221,235,.71);
  border-radius: 5px;
}



#rightcontainer{
  width: 100%; 
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
#dialogfornext{
  text-align: left;
  /* background-color: #f5f5f5; */
  align-items: left;
  /* top: 30%; */
  margin: 8%;
  width: 80%;
  /* border: 2px solid darkblue; */
  position: relative;
  padding: 15px;
  border-radius: 5px;
}

.dilogbtn{
  text-align: center;
  margin: 10px;
}

#holdpagetwosec{
  text-align: center;
 
  color: #fff;
  margin: 15%;
  position: fixed;
}

.holdpage{

}



.subcontainers{
  min-height: 100%;
  background: yellow;
}

#paticipantcontainer{
    height: auto;
    padding: 10px;
}

#questionsform{ 
  min-height: 350px;
  /* background-color: gray; */
  position: relative;
  border-radius: 18px;
  color: #898989;
  margin-top: 80px;
 
}
#questionsform hr{
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 80px;
}


#questiontext{

    margin-right: 100px;
    margin-left: 100px;
    margin-bottom: 20px;
   
}

#optionstext{
  /* text-align: left;
  font-size: 20px;
  padding-top: 10px;
  margin-top: 5px;*/
  margin-left: 100px; 
}

#optionstext ul{
    list-style: none;
}


#buttons{
  margin-top: 20px;
  margin-right: 100px;
  float: right;
}



#nextpre{
    height: 40px;
    width: 100px;
    background: #2f9bfb;
    color: white;
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;
    border: none;
    outline: none;
}

#nextpre:hover {
    background-color: blue;
    color: white;
}

#rangetext{
     margin-left: 50px;
     margin-top: 10px;
    
}

#rangetext input{
  
    border: 1px solid #2f9bfb;
    outline: none;
    border-radius: 5px;
    padding: 8px;

}
#rangetext input:focus{
   /* box-shadow: 0px 1px 8px 6px rgba(7, 4, 4, 0.3);*/

    /* border: 2px solid #434bdf !important; */
}


 /* ====================================== */
  
  .container {
    display: block;
    position: relative;
    margin-bottom: 17px;
    cursor: pointer;
    margin-left: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
 
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  

  
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #ccc;
  }
  

  
  .container input:checked ~ .checkmark{
    background-color: #434bdf;
    transition: 0.5s;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container .checkmark:after {
    left: 7px;
    top:3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }



  #optiontext{
    font-size: 18px;
    margin-left: 30px;
  }
  #optiontext p{ 
    color: blue;
  }



  
  .checkmarkradio {
    position: absolute;
    top: 5px;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 13px;
    border: 1px solid #ccc;
   
  }
  

  
  .container input:checked ~ .checkmarkradio{
    background-color: #434bdf;
    transition: 0.5s;
    
  }

  .checkmarkradio:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmarkradio:after {
    display: block;
  }
  
  .container .checkmarkradio:after {
    left: 7px;
    top:3px;
    width: 5px;
    height: 10px;
    
    /* border: solid #fff; */
    /* border-width: 0 2px 2px 0; */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }






  .containersec{
    display: block;
    font-size: 18px;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    padding-left: 35px;
    margin-left: 50px;
     -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .containersec input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  

  
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #ccc;
  }
  

  
  .containersec input:checked ~ .checkmark{
    background-color: #434bdf;
    transition: 0.5s;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .containersec input:checked ~ .checkmark:after {
    display: block;
  }
  
  .containersec .checkmark:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }






  /* select dropdown */

  #selectdropdown{
    margin-top: 5px;
    margin-left: 50px;
  }


  #selectdropdown select{
 
    height: 30px;
    width: 200px;
    border-radius: 5px;
    outline: none;
    background-color: white;
    color: black;
    font-size: 15px;
    border: 1px solid #2f9bfb;
  }

  #selectdropdown select:focus{

  }


  /*  */
  .formgroup{
    display: flex;
    justify-content: space-between;
    margin-top: 1.4em;
}

.formgroup input{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(2.25rem + 2px);
}

.formgroup input:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
} 
.formgroup select{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(2.25rem + 2px);
}

.formgroup select:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
} 

.list-btn{
  padding: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: #8226f5;
  color: white;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  padding-left: 20px;
    padding-right: 20px;
  
}


.list-btn:hover{
  background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important;   
  /* background-color: #4a19c8; */
}


#main{
  /* The image used */
  background-image: url("../../Image/background_participant.jpg");

  height: 100vh;


  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.showmsg{
  position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 25px;
    color: #6d81bf;
}
