.participant-container {
    background-image: url("../../Image/background_participant.jpg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.demographics-container {
    margin: auto;
    max-width: 1440px;
    padding-left: 16px;
    padding-right: 16px;
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.demographics-container > .card {
    width: 80%;
    height: 80vh;
    margin: auto;
}
.card-header > h4 {
    margin: 0.8rem;
}
.list-btn{
    padding: 10px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    padding-left: 20px;
    padding-right: 20px;

}
.list-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important;
}
.demographics-container > .card > .card-body {
    height: calc(100% - 145px);
    overflow-y: auto;
    max-height: calc(100% - 145px);
}
.question-title {
    padding: 16px;
    display: flex;
    justify-content: left;
    margin-bottom: 0.8rem;
}
/********************utility****************/

.custom-input {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.custom-input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}
.custom-input:hover input ~ .checkmark {
    background-color: #ccc;
}
.custom-input input:checked ~ .checkmark {
    background-color: #b80ebb;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.custom-input input:checked ~ .checkmark:after {
    display: block;
}
.custom-input .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Create a custom radio button */
.radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}
.custom-input:hover input ~ .radiomark {
    background-color: #ccc;
}
.custom-input input:checked ~ .radiomark {
    background-color: #b80ebb;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.custom-input input:checked ~ .radiomark:after {
    display: block;
}
.custom-input .radiomark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

/* Create a custom select box */
select.custom-select {
    width: 100%;
    height: 50px;
    font-size: 100%;
    font-weight: bold;
    cursor: pointer;
    border-radius: 12px;
    background-color: transparent;
    border: 2px solid #b80ebb;
    color: black;
    padding: 10px;
    padding-right: 38px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: color 0.3s ease, background-color 0.3s ease, border-bottom-color 0.3s ease;
}
select.custom-select::-ms-expand {
    display: none;
}
select.custom-select {
    background-image:
            linear-gradient(45deg, transparent 50%, #b80ebb 50%),
            linear-gradient(135deg, #b80ebb 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
    background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
}
select.custom-select:focus {
    background-image:
            linear-gradient(45deg, #b80ebb 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, #b80ebb 50%),
            linear-gradient(to right, #ccc, #ccc);
    background-position:
            calc(100% - 15px) 1em,
            calc(100% - 20px) 1em,
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
    outline: 0;
}

/* Create a custom form input */
input.form-input {
    height: 25px;
    font-size: 100%;
    font-weight: bold;
    cursor: pointer;
    border-radius: 12px;
    background-color: transparent;
    border: 2px solid #b80ebb;
    color: black;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: color 0.3s ease, background-color 0.3s ease, border-bottom-color 0.3s ease;
}
input.form-input:focus,
input.form-input:hover {
    border: 2px solid #8226f5;
}

.card {
    position: relative;
    background-color: #fefffa;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.card-body {
    padding: 12px;
    overflow:auto; 
    position:absolute;  
    top: 60px;
    left:0px; 
    right:16px;
    bottom:60px;
}
.card-header {
    padding: 6px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
}
.card-footer {
    position: absolute;
    bottom: 0;
    padding: 12px;
    border-top: 0.5px solid lightgray;
    width: calc(100% - 24px);
}
.d-flex {
    display: flex;
    margin-left: 60px;
}
.align-self-center {
    align-self: center;
}
.text-center {
    text-align: center;
}
.float-right {
    float: right;
}
.flex-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.justify-content-center {
    justify-content: center;
}
.h-100 {
    height: 100%;
}
.w-100 {
    width: 100%;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/********************media query****************/

@media only screen and (max-width: 768px) {
    .hide-on--mobile {
        display: none;
    }
    .hide-on--desktop {
        display: block;
    }
    .participant-container {
        background-position: unset;
    }
    .demographics-container > .card {
        width: 90%;
    }
}
@media only screen and (min-width: 769px) {
    .hide-on--mobile {
        display: block;
    }
    .hide-on--desktop {
        display: none;
    }
}