body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading-text {
  padding: 5px 0px 5px 0px;
  display: inline-block;
  font-size: 1.8em;

 }

.question{
  text-align: left;
}

.participant-container{
  /* width: 50%;
  margin: 5% auto; */
}

.participant-header{
  color: blueviolet;
  text-align: center;
  margin-top: 50px;
}

#maincontainer{
  /* min-height: 100vh; */

  /* background: linear-gradient(130deg, #141414, 76%, #14ABCF 76%); */
  /* background-color: blue; */
  min-width: 100%;
  display: flex;
  box-shadow: 0 0 4px 2px rgba(235,221,235,.71);
  border-radius: 5px;
}



#rightcontainer{
  width: 100%; 
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
#dialogfornext{
  text-align: left;
  /* background-color: #f5f5f5; */
  align-items: left;
  /* top: 30%; */
  margin: 8%;
  width: 80%;
  /* border: 2px solid darkblue; */
  position: relative;
  padding: 15px;
  border-radius: 5px;
}

.dilogbtn{
  text-align: center;
  margin: 10px;
}

#holdpagetwosec{
  text-align: center;
 
  color: #fff;
  margin: 15%;
  position: fixed;
}

.holdpage{

}



.subcontainers{
  min-height: 100%;
  background: yellow;
}

#paticipantcontainer{
    height: auto;
    padding: 10px;
}

#questionsform{ 
  min-height: 350px;
  /* background-color: gray; */
  position: relative;
  border-radius: 18px;
  color: #898989;
  margin-top: 80px;
 
}
#questionsform hr{
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 80px;
}


#questiontext{

    margin-right: 100px;
    margin-left: 100px;
    margin-bottom: 20px;
   
}

#optionstext{
  /* text-align: left;
  font-size: 20px;
  padding-top: 10px;
  margin-top: 5px;*/
  margin-left: 100px; 
}

#optionstext ul{
    list-style: none;
}


#buttons{
  margin-top: 20px;
  margin-right: 100px;
  float: right;
}



#nextpre{
    height: 40px;
    width: 100px;
    background: #2f9bfb;
    color: white;
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;
    border: none;
    outline: none;
}

#nextpre:hover {
    background-color: blue;
    color: white;
}

#rangetext{
     margin-left: 50px;
     margin-top: 10px;
    
}

#rangetext input{
  
    border: 1px solid #2f9bfb;
    outline: none;
    border-radius: 5px;
    padding: 8px;

}
#rangetext input:focus{
   /* box-shadow: 0px 1px 8px 6px rgba(7, 4, 4, 0.3);*/

    /* border: 2px solid #434bdf !important; */
}


 /* ====================================== */
  
  .container {
    display: block;
    position: relative;
    margin-bottom: 17px;
    cursor: pointer;
    margin-left: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
 
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  

  
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #ccc;
  }
  

  
  .container input:checked ~ .checkmark{
    background-color: #434bdf;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container .checkmark:after {
    left: 7px;
    top:3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }



  #optiontext{
    font-size: 18px;
    margin-left: 30px;
  }
  #optiontext p{ 
    color: blue;
  }



  
  .checkmarkradio {
    position: absolute;
    top: 5px;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 13px;
    border: 1px solid #ccc;
   
  }
  

  
  .container input:checked ~ .checkmarkradio{
    background-color: #434bdf;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    
  }

  .checkmarkradio:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmarkradio:after {
    display: block;
  }
  
  .container .checkmarkradio:after {
    left: 7px;
    top:3px;
    width: 5px;
    height: 10px;
    
    /* border: solid #fff; */
    /* border-width: 0 2px 2px 0; */
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }






  .containersec{
    display: block;
    font-size: 18px;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    padding-left: 35px;
    margin-left: 50px;
     -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .containersec input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  

  
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #ccc;
  }
  

  
  .containersec input:checked ~ .checkmark{
    background-color: #434bdf;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .containersec input:checked ~ .checkmark:after {
    display: block;
  }
  
  .containersec .checkmark:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }






  /* select dropdown */

  #selectdropdown{
    margin-top: 5px;
    margin-left: 50px;
  }


  #selectdropdown select{
 
    height: 30px;
    width: 200px;
    border-radius: 5px;
    outline: none;
    background-color: white;
    color: black;
    font-size: 15px;
    border: 1px solid #2f9bfb;
  }

  #selectdropdown select:focus{

  }


  /*  */
  .formgroup{
    display: flex;
    justify-content: space-between;
    margin-top: 1.4em;
}

.formgroup input{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(2.25rem + 2px);
}

.formgroup input:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
} 
.formgroup select{
    outline: none;
    padding: 0 0.375rem 0 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: black;
    background-color: #fafafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(2.25rem + 2px);
}

.formgroup select:focus{
    border: 1px solid #959595;
    background-color: #FFFFFF;
    color:  #959595;;
} 

.list-btn{
  padding: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: #8226f5;
  color: white;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  padding-left: 20px;
    padding-right: 20px;
  
}


.list-btn:hover{
  background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important;   
  /* background-color: #4a19c8; */
}


#main{
  /* The image used */
  background-image: url(/static/media/background_participant.72cb12db.jpg);

  height: 100vh;


  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.showmsg{
  position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 25px;
    color: #6d81bf;
}

.participant-container {
    background-image: url(/static/media/background_participant.72cb12db.jpg);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.demographics-container {
    margin: auto;
    max-width: 1440px;
    padding-left: 16px;
    padding-right: 16px;
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.demographics-container > .card {
    width: 80%;
    height: 80vh;
    margin: auto;
}
.card-header > h4 {
    margin: 0.8rem;
}
.list-btn{
    padding: 10px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: #8226f5;
    color: white;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
    padding-left: 20px;
    padding-right: 20px;

}
.list-btn:hover{
    background-image: -webkit-linear-gradient(45deg,#7025d1 0%, #b80ebb 100%) !important;
}
.demographics-container > .card > .card-body {
    height: calc(100% - 145px);
    overflow-y: auto;
    max-height: calc(100% - 145px);
}
.question-title {
    padding: 16px;
    display: flex;
    justify-content: left;
    margin-bottom: 0.8rem;
}
/********************utility****************/

.custom-input {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.custom-input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}
.custom-input:hover input ~ .checkmark {
    background-color: #ccc;
}
.custom-input input:checked ~ .checkmark {
    background-color: #b80ebb;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.custom-input input:checked ~ .checkmark:after {
    display: block;
}
.custom-input .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Create a custom radio button */
.radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}
.custom-input:hover input ~ .radiomark {
    background-color: #ccc;
}
.custom-input input:checked ~ .radiomark {
    background-color: #b80ebb;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.custom-input input:checked ~ .radiomark:after {
    display: block;
}
.custom-input .radiomark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

/* Create a custom select box */
select.custom-select {
    width: 100%;
    height: 50px;
    font-size: 100%;
    font-weight: bold;
    cursor: pointer;
    border-radius: 12px;
    background-color: transparent;
    border: 2px solid #b80ebb;
    color: black;
    padding: 10px;
    padding-right: 38px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-bottom-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-bottom-color 0.3s ease;
}
select.custom-select::-ms-expand {
    display: none;
}
select.custom-select {
    background-image:
            linear-gradient(45deg, transparent 50%, #b80ebb 50%),
            linear-gradient(135deg, #b80ebb 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
    background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
}
select.custom-select:focus {
    background-image:
            linear-gradient(45deg, #b80ebb 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, #b80ebb 50%),
            linear-gradient(to right, #ccc, #ccc);
    background-position:
            calc(100% - 15px) 1em,
            calc(100% - 20px) 1em,
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
    outline: 0;
}

/* Create a custom form input */
input.form-input {
    height: 25px;
    font-size: 100%;
    font-weight: bold;
    cursor: pointer;
    border-radius: 12px;
    background-color: transparent;
    border: 2px solid #b80ebb;
    color: black;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-bottom-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-bottom-color 0.3s ease;
}
input.form-input:focus,
input.form-input:hover {
    border: 2px solid #8226f5;
}

.card {
    position: relative;
    background-color: #fefffa;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.card-body {
    padding: 12px;
    overflow:auto; 
    position:absolute;  
    top: 60px;
    left:0px; 
    right:16px;
    bottom:60px;
}
.card-header {
    padding: 6px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
}
.card-footer {
    position: absolute;
    bottom: 0;
    padding: 12px;
    border-top: 0.5px solid lightgray;
    width: calc(100% - 24px);
}
.d-flex {
    display: flex;
    margin-left: 60px;
}
.align-self-center {
    align-self: center;
}
.text-center {
    text-align: center;
}
.float-right {
    float: right;
}
.flex-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.justify-content-center {
    justify-content: center;
}
.h-100 {
    height: 100%;
}
.w-100 {
    width: 100%;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/********************media query****************/

@media only screen and (max-width: 768px) {
    .hide-on--mobile {
        display: none;
    }
    .hide-on--desktop {
        display: block;
    }
    .participant-container {
        background-position: unset;
    }
    .demographics-container > .card {
        width: 90%;
    }
}
@media only screen and (min-width: 769px) {
    .hide-on--mobile {
        display: block;
    }
    .hide-on--desktop {
        display: none;
    }
}
